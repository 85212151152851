import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday, October 6th, we will host a nutrition seminar starting at
11:00am.  This is free for our members but you are welcome to invite
friends.  Non members will only pay \\$25.  This should last about an
hour and a half.  This will be the same, individual specific guidelines
Daniel has successfully used with numerous personal training clients as
well as CrossFit members throughout the years.  There will be no
advocating of crazy fad diets, or of the latest miracle supplement (that
doesn’t exist).  Just useful, well established information for
individuals to change their eating habits in order to gain muscle, lose
bodyfat and recover from intense workouts.  All while allowing room for
eating the foods you crave from time to time!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      